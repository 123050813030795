<template>
	<div class="container">
		<div class="search" style="display:flex">
			<el-form
				ref="queryFormRef"
				style="flex:1"
				:inline="true"
				:model="search"
				class="demo-form-inline"
				size="mini"
			>
				<el-form-item label="内部型号：" prop="model_code">
					<SearchInnerModel
						v-model="search.model_code"
						@selectQuery="Search"
					></SearchInnerModel>
				</el-form-item>
				<!-- <el-form-item label="外部型号：" prop="model_name">
					<SearchOuterModel v-model="search.model_name"></SearchOuterModel>
				</el-form-item> -->
				<el-form-item label="品牌：" prop="brand_name">
					<SearchBrandList
						v-model="search.client_brand_code"
						@selectQuery="Search"
					></SearchBrandList>
				</el-form-item>
				<el-form-item label="设备状态：" prop="p2p_online">
					<el-select
						v-model="search.p2p_online"
						placeholder=" "
						clearable
						@change="Search"
					>
						<el-option label="在线" :value="1" />
						<el-option label="不在线" :value="2" />
					</el-select>
				</el-form-item>
				<el-form-item label="设备uuid：" prop="uuid">
					<el-input
						v-model.trim="search.uuid"
						placeholder=" "
						@keyup.enter.native="Search"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-button
						type="primary"
						style="margin-left: 16px;"
						class="btnClass"
						@click="Search"
						>搜索</el-button
					>
					<el-button
						type="text"
						class="textBtn"
						style="padding-bottom: 0px;vertical-align: bottom;"
						@click="clearCondition"
						>重置</el-button
					>
				</el-form-item>
			</el-form>
			<div>
				<!-- <el-button type="primary" size="small" @click="addcontent">新建</el-button> -->
			</div>
		</div>
		<!-- 列表 -->
		<el-table :data="list" size="mini">
			<el-table-column label="设备UUID" width="320">
				<template v-slot="scope">
					<span>{{ scope.row.uuid }}</span>
				</template>
			</el-table-column>
			<el-table-column label="内部型号">
				<template v-slot="scope">
					<router-link
						:to="
							'/iot/promodel/modelconfig/' + scope.row.model_code + '&&online'
						"
					>
						<span style="color:blue">{{ scope.row.model_code }}</span>
					</router-link>
				</template>
			</el-table-column>
			<el-table-column label="设备名称">
				<template v-slot="scope">
					<span>{{ scope.row.name }}</span>
				</template>
			</el-table-column>
			<el-table-column
				label="创建时间"
				prop="create_time"
				:formatter="
					(row) => row.create_time && util.dateFormat(row.create_time)
				"
			>
			</el-table-column>
			<el-table-column label="设备状态">
				<template v-slot="scope">
					<span style="color:#36C626" v-if="scope.row.p2p_online == 1"
						>在线</span
					>
					<span style="color:#FF1A2E" v-else>不在线</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="150px">
				<template v-slot="scope">
					<router-link :to="'/iot/onlineinfo/' + scope.row.uuid">
						<el-button type="info" size="mini" class="btnClass">详情</el-button>
					</router-link>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			@current-change="handleCurrentChange"
			v-model:currentPage="search.page"
			:page-size="search.limit"
			layout="total, prev, pager, next"
			:total="total"
			background
		>
		</el-pagination>

		<!-- 新增修改 -->
		<el-dialog
			:title="title + '设备出厂'"
			v-model="dialogFormVisible"
			width="600px"
		>
			<el-form :model="form" :rules="rules" ref="form">
				<el-form-item
					label="产品型号编号"
					prop="product_model_code"
					label-width="110px"
				>
					<el-select v-model="form.product_model_code">
						<el-option
							v-for="item in promodel"
							:key="item.code"
							:label="item.name"
							:value="item.code"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="设备唯一编号" prop="uuid" label-width="110px">
					<el-input v-model="form.uuid" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="设备秘钥" prop="uuid_secret" label-width="110px">
					<el-input v-model="form.uuid_secret" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="出厂mac信息" prop="mac" label-width="110px">
					<el-input v-model="form.mac" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="出厂sn信息" prop="sn" label-width="110px">
					<el-input v-model="form.sn" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="状态" prop="status" label-width="110px">
					<el-select v-model="form.status" clearable placeholder="请选择">
						<el-option label="待激活" :value="1"></el-option>
						<el-option label="已激活" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="设备来源" prop="brand" label-width="110px">
					<el-input v-model="form.brand" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogFormVisible = false">取 消</el-button>
					<el-button type="primary" v-if="!edit" @click="Validate(addconfirm)"
						>新增</el-button
					>
					<el-button type="primary" v-if="edit" @click="Validate(editconfirm)"
						>修改</el-button
					>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script type="text/ecmascript-6">

import SearchInnerModel from '../../../components/device/SearchInnerModel.vue'
import SearchOuterModel from '../../../components/device/SearchOuterModel.vue'
import SearchBrandList from '../../../components/device/SearchBrandList.vue'
import util from '../../../util/util'

export default {
  name: '',
  mixins: [],
  components: {
    SearchInnerModel,
    SearchOuterModel,
    SearchBrandList,
  },
  props: {},
  data () {
    return {
      util: util,
      search: {
        page: 1,
        limit: 16,
        model_code: '',
        // model_name: '', // 先去掉外部型号 2022/6/29
        client_brand_code: '',
        p2p_online: '',
        uuid: '',
      },
      list: [],
      total: 0,
      title: '',
      dialogFormVisible: false,
      edit: false,
      form: {
        id: '',
        product_model_code: '',
        uuid: '',
        uuid_secret: '',
        mac: '',
        sn: '',
        status: '',
        brand: '',
      },
      rules: {
        product_model_code: [{ required: true, message: '请输入产品型号编号', trigger: 'blur' }],
        uuid: [{ required: true, message: '请输入设备唯一编号', trigger: 'blur' }],
        uuid_secret: [{ required: true, message: '请输入设备秘钥', trigger: 'blur' }],
        mac: [{ required: true, message: '请输入出厂mac信息', trigger: 'blur' }],
        sn: [{ required: true, message: '请输入出厂sn信息', trigger: 'blur' }],
        status: [{ required: true, message: '请输入状态', trigger: 'blur' }],
        brand: [{ required: true, message: '请输入设备来源', trigger: 'blur' }],
      },
      promodel: [],
      modelcodelist: {},
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      sessionStorage.setItem('currentPage',val);
      this.Search()
    },
    Search(){
      this.api.OnlineList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    addcontent () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.form = {}
      this.edit = false
      this.title = '新增'
      this.dialogFormVisible = true
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    addconfirm(){
      this.update()
    },
    update(){
      this.api.DeliveryUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title +'成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    editconfirm(){
      this.update()
    },
    getSchoolWebModuleMessageListFunc(){
      setTimeout(() => {
        if(sessionStorage.getItem("currentPage") && sessionStorage.getItem("currentPage") !== 1){
          this.search.page = Number(sessionStorage.getItem("currentPage"))
        }
        this.Search()
      },0)
    },
	clearCondition() {
		this.reset()
		this.Search()
	},
	reset() {
      this.search = {
        ...this.search,
        model_code: '',
        client_brand_code: '',
        p2p_online: '',
        uuid: ''
      }
    },
  },
  filters: {},
  mounted () {
    this.getSchoolWebModuleMessageListFunc()
  },
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.container {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	.search {
		background: #F0F2F5;
		padding-top: 16px;
		margin-bottom: 24px;
	}
	.el-form {
		::v-deep(.el-form-item__label) {
			font-family: 'Manrope';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			color: #000000;
            opacity: 0.8;
			padding-right: 0px;
		}
		.el-form-item {
			margin-left: 24px;
		}
	}
	.btnClass {
		color: #FFF;
		background-color: #FB6D07;
		border-color: #FB6D07;
	}
	.textBtn {
		color: #FB6D07;
	}
	::v-deep(thead) {
		font-family: 'Manrope';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		color: #000;
		opacity: 0.8;
		th {
			font-weight: 400;
			background-color: #F0F2F5;
		}
	}
  ::v-deep(tbody) {
    tr {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      opacity: 0.8;
    }
  }

}
</style>
