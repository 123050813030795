<template>
	<el-select
		v-model="model"
		filterable
		clearable
		remote
		reserve-keyword
		placeholder=" "
		:remote-method="remoteBrandList"
		:loading="loading"
		@change="handleChange"
	>
		<el-option
			v-for="item in options"
			:key="item.value"
			:label="item.label"
			:value="item.value"
		/>
	</el-select>
</template>

<script>
export default {
	name: 'SearchBrandList',
	props: ['modelValue'],
	emits: ['update:modelValue'],
	data() {
		return {
			loading: false,
			options: [],
		}
	},
	created() {
		this.remoteBrandList()
	},
	computed: {
		model: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			},
		},
	},
	methods: {
		remoteBrandList(query) {
			this.loading = true
			this.api
				.BrandList({
					page: 1,
					limit: 100,
					kname: query || '',
				})
				.then((res) => {
					if (res.data.code == 200) {
						const { data } = res.data.data
						this.options = data.map((item) => ({
							value: item.code,
							label: item.name,
						}))
						this.loading = false
						return
					}
					this.loading = false
					this.$message.error('获取失败' + res.data.msg)
				})
		},
		handleChange() {
			this.$emit('selectQuery')
		},
	},
}
</script>
